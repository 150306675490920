<template>
  <div>
    <a-pagination
      class="ant-pagination ant-table-pagination"
      v-model="current"
      :page-size="pageSize"
      :total="total"
      :show-total="total => `共 ${total} 条数据`"
      show-quick-jumper
    />
  </div>
</template>

<script>
export default {
  name: "TokenPagination",
  props: ["total", "page", "pageSize", "nextToken"],
  data () {
    return {
      current: 1,
    }
  },
  watch:{
    page:{
      handler(newVal){
        this.current = newVal
      },
      immediate:true
    },
    current:{
      handler(newVal){
        this.$emit("change", newVal)
      }
    }
  }
};
</script>
