/*
  跳转到合同详情页的超链接
*/
<template>
  <a class="blue-color" @click="gotoContractDetail(contractId)">{{
    contractCode
  }}</a>
</template>
<script>
export default {
  props: {
    contractId: {
      type: [String, Number],
    },
    contractCode: {
      type: String,
    },
  },
  methods: {
    gotoContractDetail(id) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: id },
      });
    },
  },
};
</script>
<style scoped>
</style>